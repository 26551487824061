import React from 'react';
import { useSelector } from 'react-redux';
import HomeContainer from '../components/home/home_container';
import { ScrollToTop } from '../components/scroll_to_top/scroll_to_top';
import { SEO } from '../components/seo/seo';
import { getBaseUrl } from '../redux/router';
import logoLake from './home_page/logo_lake_background.png';

const schema = `
{
  "@context": "http://www.schema.org",
  "@type": "Organization",
  "name": "Omnia Fishing",
  "image": "https://omniafishing.com/home-hero.jpg",
  "url": "https://www.omniafishing.com/",
  "sameAs": [
    "https://www.facebook.com/OmniaFishing/",
    "https://www.linkedin.com/company/omniafishing/",
    "https://www.youtube.com/channel/UC8VoqtTzNN09TSycM4rHHBQ"
  ],
  "logo": "https://www.omniafishing.com/logo.svg",
  "description": "Omnia Fishing is the first e-commerce store that allows anglers to find, buy, and organize their fishing gear based on the bodies of water they fish with a 'shop-by-lake' concept. Anglers rely on information specific to their water body, species, styles, season and others. There’s a lot of variables to selecting the right fishing gear and we’re the first retail experience that creates a custom, ML recommendation that starts with water body attributes like clarity, depth, and other characteristics to create unique catching opportunities. With over 5,000 SKUs manufactured by category captains like Rapala, 13 Fishing, and Live Target, we ship product directly to our customers out of our warehouse in Golden Valley, MN.",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+16514474094",
    "contactType": "Customer Support"
  },
    "telephone": "+16514474094"
}
`;

export const HomePage = () => {
  const baseUrl = useSelector(getBaseUrl);

  return (
    <ScrollToTop>
      <section>
        <SEO title="Shop by Lake for Fishing Gear Online" ogImage={`${baseUrl}${logoLake}`}></SEO>

        <HomeContainer />
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schema }} />
      </section>
    </ScrollToTop>
  );
};

export default HomePage;
