import React from 'react';
import { useFrontload } from 'react-frontload';
import { useDispatch } from 'react-redux';
import { apiV1 } from '../../lib/api';
import { PrismicDocumentTags } from '../../prismic';
import { fetchPage } from '../../redux/pages';
import { fetchAmbassadors } from '../../redux/user_profile';
import { Home } from './home';

const HomeContainer = () => {
  const dispatch = useDispatch();

  const { data } = useFrontload('HomeContainer', async () => {
    const [mediaRes] = await Promise.all([
      apiV1.mediaFetch({
        tags: [PrismicDocumentTags.HOME],
        per_page: 6,
      }),
      dispatch(fetchPage('home')),
      dispatch(
        fetchAmbassadors({
          exclude: ['brands', 'lists', 'organizations', 'species', 'waterbodies'],
        })
      ),
    ]);
    return {
      media: mediaRes.data.data,
    };
  });

  return <Home media={data?.media || []} />;
};

export default HomeContainer;
